import Locations from '@/wam/config/Locations.json';

const IS_LOCAL = process.env.NODE_ENV !== 'production';

function f_prepareLocationUrl(location_data) {
  let location_url = '';
  if (location_data['data']['general']['location_ssl']['val']['value'] === 'yes') {
    location_url += 'https://'
    location_url += location_data['data']['general']['location_domain']['val']
  } else {
    location_url += 'http://'
    location_url += location_data['data']['general']['location_domain']['val']
    if (location_data['data']['general']['location_port'] && location_data['data']['general']['location_port']['val']) {
      location_url += ':' + location_data['data']['general']['location_port']['val']
    }
  }
  return location_url;
};

let production_API_BASE_URL = 'https://wisdomera-api.wisdomera.io/'
let production_API_FRONTEND_URL = 'https://wisdomera.wisdomera.io/'
let production_API_WMANAGER_URL = 'https://manager-api.wisdomera.io/'
let production_API_WANALYZER_URL = 'https://analyzer-api.wisdomera.io/'
let production_API_POLESTAR_URL = 'https://analyzer-api.wisdomera.io/'
let production_API_WALGORITHM_URL = 'https://algorithm-api.wisdomera.io/'
let production_API_WRULEENGINE_URL = 'https://ruleengine-api.wisdomera.io/'
let production_WDMR_URL = 'https://wdmr.wisdomera.io/'
try {
  for (let i in Locations) {
    if (Locations[i].data.general.location_name.val === 'API_BASE_URL') {
      production_API_BASE_URL = f_prepareLocationUrl(Locations[i]);
    } else if (Locations[i].data.general.location_name.val === 'API_FRONTEND_URL') {
       production_API_FRONTEND_URL = f_prepareLocationUrl(Locations[i]);
    } else if (Locations[i].data.general.location_name.val === 'API_WMANAGER_URL') {
      production_API_WMANAGER_URL = f_prepareLocationUrl(Locations[i]);
    } else if (Locations[i].data.general.location_name.val === 'API_WANALYZER_URL') {
      production_API_WANALYZER_URL = f_prepareLocationUrl(Locations[i]);
    } else if (Locations[i].data.general.location_name.val === 'API_POLESTAR_URL') {
      production_API_POLESTAR_URL = f_prepareLocationUrl(Locations[i]);
    } else if (Locations[i].data.general.location_name.val === 'API_WALGORITHM_URL') {
      production_API_WALGORITHM_URL = f_prepareLocationUrl(Locations[i]);
    } else if (Locations[i].data.general.location_name.val === 'API_WRULEENGINE_URL') {
      production_API_WRULEENGINE_URL = f_prepareLocationUrl(Locations[i]);
    } else if (Locations[i].data.general.location_name.val === 'WDMR_URL') {
      production_WDMR_URL = f_prepareLocationUrl(Locations[i]);
    }
  }
} catch (err) {}

// console.log('production_API_BASE_URL           : ', production_API_BASE_URL);
// console.log('production_API_FRONTEND_URL       : ', production_API_FRONTEND_URL);
// console.log('production_API_WMANAGER_URL       : ', production_API_WMANAGER_URL);
// console.log('production_API_WANALYZER_URL      : ', production_API_WANALYZER_URL);
// console.log('production_API_POLESTAR_URL       : ', production_API_POLESTAR_URL);
// console.log('production_API_WALGORITHM_URL     : ', production_API_WALGORITHM_URL);
// console.log('production_API_WRULEENGINE_URL    : ', production_API_WRULEENGINE_URL);
// console.log('production_WDMR_URL               : ', production_WDMR_URL);

const API_BASE_URL = IS_LOCAL ? 'http://localhost:8888/': production_API_BASE_URL;
// const API_BASE_URL = 'https://wisdomera-api.wisdomera.io/';
// const API_BASE_URL = 'https://wisdomera-api.mlpcare.com/';

const API_FRONTEND_URL = IS_LOCAL ? 'http://localhost:8081/' : production_API_FRONTEND_URL;
const API_WMANAGER_URL = IS_LOCAL ? 'http://localhost:8897/' : production_API_WMANAGER_URL;
const API_WANALYZER_URL = IS_LOCAL ? 'http://localhost:8889/' : production_API_WANALYZER_URL;
const API_POLESTAR_URL = IS_LOCAL ? 'http://localhost:8889/' : production_API_POLESTAR_URL;
const API_WALGORITHM_URL = IS_LOCAL ? 'http://localhost:8899/' : production_API_WALGORITHM_URL;
const API_WRULEENGINE_URL = IS_LOCAL ? 'http://localhost:8887/' : production_API_WRULEENGINE_URL;
const WDMR_URL = IS_LOCAL ? 'http://localhost:8077/' : production_WDMR_URL;

const CLIENT_ID = 'wisdomhs';
const CLIENT_SECRET = 'wisdomera';
const GRANT_TYPE = 'password';

const WDMR_LINKS = {
  'record': WDMR_URL + 'wdmr-record?',
  'show': WDMR_URL + 'wdmr-show?',
  'wdmr_data_show': WDMR_URL + 'wdmr-data-show?',
  'wdmr_data_record': WDMR_URL + 'wdmr-data-record?'
};

export {
  IS_LOCAL,
  API_BASE_URL,
  CLIENT_ID,
  CLIENT_SECRET,
  API_FRONTEND_URL,
  GRANT_TYPE,
  API_WMANAGER_URL,
  API_POLESTAR_URL,
  API_WANALYZER_URL,
  API_WRULEENGINE_URL,
  API_WALGORITHM_URL,
  WDMR_LINKS,
  WDMR_URL
};
